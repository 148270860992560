@import '~app/mixins';

.order {
  width: 100%;
  border: 1px solid #d8dbdf;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  background-color: $color-alabaster;
}

.billing {
  margin: 20px;

  .credit {
    margin-bottom: 8px;
    color: #666;
    font-size: 13px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit {
  margin-right: -15px;
}

.title {
  color: $color-grey-1;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.preview {
  width: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  background-color: $color-grey-lighter;
  box-shadow: inset 0 1px 2px 0 rgba(81, 81, 81, 0.5);
  padding: 5px;
  margin: 20px auto;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.postcards {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    border-top: 1px dotted $color-grey-1;
  }
}

.key,
.value {
  background: $color-alabaster;
  z-index: 1;
  line-height: 20px;
}

.key {
  color: $color-grey-1;
  font-size: 14px;
  padding-right: 5px;
  position: relative;
}

.value {
  color: $color-grey;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 5px;
}

.amount {
  color: $color-grey;
  font-size: 11px;
  font-style: italic;
  line-height: 13px;
}

.input {
  display: block;
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 0 0 #ececec;
  font-size: 14px;
  width: 100%;
  color: $color-grey-dark;
}

.billing {
  .billingName,
  .billingInfo {
    color: $color-grey;
    font-size: 14px;
    line-height: 19px;
  }

  .billingTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    border-bottom: 1px solid $color-grey-light;

    .smallTitle {
      color: $color-grey-1;
      text-decoration: none;
    }

    .paymentMethod {
      font-size: 11px;
      cursor: pointer;

      @include primary-color();

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .billingName {
    font-weight: bold;
  }

  .billingAddress {
    flex: 1;
  }

  .billingInfo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .billingNum {
    text-align: right;
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .cardCode {
    display: flex;
    align-items: center;
    margin-top: 8px;

    label {
      margin-right: 5px;
    }

    input {
      width: 55px;
    }
  }
}

.overdue {
  border-radius: 5px;
  border: 1px solid $color-red;
  color: $color-red;
  background-color: #fbcbc0;
  margin: 10px 0;
  padding: 6px;
  font-size: 13px;
  text-align: left;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.buttons {
  display: flex;
  margin: 0 20px;
  justify-content: center;

  button {
    min-width: 125px;

    + button {
      margin-left: 10px;
    }
  }
}

.message {
  color: red;
  font-size: 15px;
  margin: 20px 0;

  a {
    color: red;
    text-decoration: underline;
  }

  &.success {
    color: $color-green;
  }
}
